import * as actionTypes from '@actionTypes';
import { updateObject } from '@helpers/updateObject';
import { User } from 'vl-common/src/schemas/shared/user';

/* Initial */
export const initialState = {
  loading: false,
  error: null,
  // todo @Alastair - this types needs defining properly
  user: null as (User & { build_path: string }) | null,
  token: null,
  isRestricted: null,
  role: null,
  upcomingHistory: null,
  nextAppointment: null,
  policy: null,
  logRocketInitialised: false,
  hasSignedOut: false
};

type AuthState = typeof initialState;

const userRole = (state: AuthState, action: Record<any, any>) =>
  updateObject(state, {
    ...action
  });

const userFetch = (state: AuthState, action: Record<any, any>) =>
  updateObject(state, {
    loading: false,
    ...action
  });

/* Request Error */
const requestError = (state: AuthState, action: Record<any, any>) =>
  updateObject(state, {
    loading: false,
    action
  });

/* Request Success */
const requestSuccess = (state: AuthState, action: Record<any, any>) =>
  updateObject(state, { loading: false, ...action });

/* Reducer */
const reducer = (state = initialState, action: Record<any, any>) => {
  switch (action.type) {
    case actionTypes.USER_UPCOMINGHISTORY_FAIL:
      return requestError(state, action);
    case actionTypes.AUTH_USER_SET_ROLE:
      return userRole(state, action);
    case actionTypes.AUTH_LOGOUT_SUCCESS:
    case actionTypes.AUTH_SUCCESS:
      return userFetch(state, action);
    case actionTypes.USER_UPCOMINGHISTORY_SUCCESS:
      return requestSuccess(state, { upcomingHistory: action.payload });
    case actionTypes.USER_NEXT_APPOINTMENT_SUCCESS:
      return requestSuccess(state, { nextAppointment: action.payload });
    case actionTypes.USER_POLICY_SUCCESS:
      return requestSuccess(state, { policy: action.payload });
    case actionTypes.LOG_ROCKET_INITIALISED:
      return { ...state, logRocketInitialised: true };
    case actionTypes.AUTH_SIGNED_OUT:
      return { ...state, hasSignedOut: true };
    case actionTypes.AUTH_SIGNED_OUT_SUCCESS:
      return { ...state, hasSignedOut: false };
    case actionTypes.USER_POLICY_ACCEPTED_SUCCESS:
      return {
        ...state,
        loading: false,
        policy: (state.policy as null | any[])?.map((p) =>
          p.policy_code === action.payload.policy_code ? action.payload : p
        )
      };
    default:
      return state;
  }
};

export default reducer;
