import { useEffect, useState } from 'react';
import { useDebouncedCallback } from '@mantine/hooks';

export const useExtremeZoom = () => {
  const [isExtremeZoom, setIsExtremeZoom] = useState(false);

  const checkViewportSize = useDebouncedCallback(() => {
    const isExtremeZoom = window.visualViewport
      ? window.visualViewport.scale > 2 || window.visualViewport.height <= 400
      : false;

    setIsExtremeZoom(isExtremeZoom);
  }, 100);

  useEffect(() => {
    checkViewportSize();

    const visualViewport = window?.visualViewport;
    if (visualViewport) {
      visualViewport.addEventListener('resize', checkViewportSize);
      visualViewport.addEventListener('scroll', checkViewportSize);
    }

    window.addEventListener('resize', checkViewportSize);

    return () => {
      if (visualViewport) {
        visualViewport.removeEventListener('resize', checkViewportSize);
        visualViewport.removeEventListener('scroll', checkViewportSize);
      }
      window.removeEventListener('resize', checkViewportSize);
    };
  }, [checkViewportSize]);

  return isExtremeZoom;
};
