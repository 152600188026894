import React, { createRef } from 'react';
import { Input, DatePicker } from 'antd';
import { SearchOutlined } from 'antd/icons';
import moment from 'moment';
import format from 'vl-common/src/lib/formatDate';

const queryChanged = (setSelectedKeys, value, dataIndex, setQuery, confirm, dateStart) => {
  if (value === 'Invalid date') {
    value = '';
  }
  setSelectedKeys(value ? [value] : []);
  setQuery((query) => ({ ...query, [dataIndex]: value === '' ? undefined : value }));
  if (dataIndex !== dateStart) {
    confirm();
  }
};

export const filterColumn = (placeholder, dataIndex, setQuery, query, exact, dateFilter, dob, filterable) => {
  const inputReference = createRef();

  // @ts-ignore
  return {
    filterDropdown: ({ setSelectedKeys, confirm }) => {
      if (!dateFilter) {
        return (
          <Input
            defaultValue={query[dataIndex]}
            placeholder={`Search ${placeholder}`}
            onPressEnter={(e) => {
              queryChanged(
                setSelectedKeys,
                e.currentTarget.value,
                dataIndex,
                setQuery,
                confirm,
                filterable.dateStartFilterKey
              );
            }}
            suffix={
              <span
                style={{
                  fontSize: 12,
                  fontWeight: 500,
                  color: '#888'
                }}
              >
                Press ↵ to search
              </span>
            }
            // @ts-ignore
            ref={inputReference}
          />
        );
      }

      if (!dob) {
        return (
          <div style={{ display: 'grid' }}>
            <DatePicker
              defaultValue={
                query[filterable.dateStartFilterKey] ? moment(query[filterable.dateStartFilterKey], 'YYYY-MM-DD') : null
              }
              placeholder="From:"
              format="YYYY-MM-DD"
              onChange={(date, ds) =>
                queryChanged(
                  setSelectedKeys,
                  ds ? format(new Date(ds), 'yyyy-MM-dd') : null,
                  filterable.dateStartFilterKey,
                  setQuery,
                  confirm,
                  ''
                )
              }
              ref={inputReference}
            />
            <DatePicker
              defaultValue={
                query[filterable.dateEndFilterKey] ? moment(query[filterable.dateEndFilterKey], 'YYYY-MM-DD') : null
              }
              placeholder="To:"
              format="YYYY-MM-DD"
              onChange={(date, ds) =>
                queryChanged(
                  setSelectedKeys,
                  ds ? format(new Date(ds), 'yyyy-MM-dd') : null,
                  filterable.dateEndFilterKey,
                  setQuery,
                  confirm,
                  filterable.dateStartFilterKey
                )
              }
            />
          </div>
        );
      }

      return (
        <DatePicker
          defaultValue={query[dataIndex] ? moment(query[dataIndex], 'YYYY-MM-DD') : moment()}
          placeholder="DD/MM/YYYY"
          format="DD/MM/YYYY"
          onChange={(e) =>
            queryChanged(
              setSelectedKeys,
              moment(e).format('YYYY-MM-DD'),
              'dob',
              setQuery,
              confirm,
              filterable.dateStartFilterKey
            )
          }
          ref={inputReference}
        />
      );
    },
    filterIcon: () => {
      const colorIcon = filterable.filtering
        ? filterable.filtering(query)
        : (filterable.dateStartFilterKey in query && query[filterable.dateStartFilterKey]) ||
          (filterable.dateEndFilterKey in query && query[filterable.dateEndFilterKey]);
      return <SearchOutlined style={{ color: colorIcon ? 'var(--productPrimary)' : undefined }} />;
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => inputReference.current.focus(), 100);
      }
    }
  };
};
